<template>
  <b-overlay
    id="overlay-background"
    :show="mergedlettersloader"
    variant="dark"
    opacity="0.85"
    blur="2px"
    rounded="sm"
    spinner-variant="primary"
    spinner-medium
  >
    <b-card
      class="card-transaction"
      no-body
    >
      <b-card-header>
        <b-card-title>Print</b-card-title>
      </b-card-header>

      <b-card-body>
        <template
          v-if="mergedLetters.length > 0"
          class="transaction-item"
        >
          <div
            v-for="letter in mergedLetters"
            :key="letter.id"
            class="transaction-item"
          >
            <b-media no-body>
              <b-media-aside>
                <b-avatar
                  rounded
                  size="42"
                  variant="light-success"
                >
                  <feather-icon
                    size="18"
                    icon="FileIcon"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body>
                <h6 class="transaction-title">
                  {{ letter.letter_group }}
                </h6>
              </b-media-body>
            </b-media>
            <div
              class="font-weight-bolder text-success"
            >
              <!-- large -->
              <b-button-group
                size="md"
              >
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  :href="letter.path"
                  target="_blank"
                >
                  Print
                </b-button>
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  @click="markAsPrinted(letter.id)"
                >
                  Mark as printed
                </b-button>
              </b-button-group>
            </div>
          </div>
          <b-row>
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted">{{ 'Showing' }} {{ from }} to {{ to }} of {{ totalPages }} {{ 'Entries' }}</span>
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >

              <b-pagination
                v-model="currentPage"
                :total-rows="totalPages"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>

            </b-col>

          </b-row>
        </template>
        <template
          v-else
        >
          <b-alert
            variant="primary"
            show
          >
            <div class="alert-body">
              <span>There are no merged letters.</span>
            </div>
          </b-alert>
        </template>
      </b-card-body>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BRow, BCol, BCard, BCardBody, BMedia, BMediaBody, BCardHeader, BCardTitle, BMediaAside, BAvatar, BButtonGroup, BButton, BOverlay, BAlert, BPagination,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
// import { ValidationProvider, ValidationObserver } from 'vee-validate'
// import { required } from '@validations'
import { EventBus } from '@/event-bus'
import { showToast } from '@core/mixins/ui/toastnotification'
import axios from '@axios'
// import vSelect from 'vue-select'

export default {
  components: {
    BRow, BCol, BCard, BCardBody, BMedia, BMediaBody, BCardHeader, BCardTitle, BMediaAside, BAvatar, BButtonGroup, BButton, BOverlay, BAlert, BPagination,
  },
  directives: {
    Ripple,
  },
  mixins: [showToast],
  data() {
    return {
      mergedLetters: [],
      mergedlettersloader: false,
      totalPages: 0,
      from: 0,
      to: 0,
      currentPage: 1,
      perPage: 10,
    }
  },
  watch: {
    currentPage: {
      handler() {
        this.getMergedLetters()
      },
    },
  },
  created() {
    this.getMergedLetters()
    EventBus.$on('lettersmerged', () => {
      this.getMergedLetters()
    })
  },
  methods: {
    getMergedLetters() {
      this.mergedlettersloader = true
      axios.get(`get-merged-letters?page=${this.currentPage}&sortDesc=true`).then(response => {
        if (response.status) {
          this.mergedLetters = response.data.data.data
          this.totalPages = response.data.data.total
          this.from = response.data.data.from
          this.to = response.data.data.to
          this.currentPage = response.data.data.current_page
          this.mergedlettersloader = false
        }
      }).catch(() => {
        this.lettersloader = false
        this.failureToast('Letters could not be fetched')
      })
    },
    markAsPrinted(id) {
      this.mergedlettersloader = true
      axios.post('print-letter', {
        id,
      }).then(response => {
        if (response.status) {
          this.getMergedLetters()
          this.successToast('Letter has been marked as printed')
        }
      }).catch(() => {
        this.failureToast('Letters could not be marked as printed')
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.spinner-border{
  width: 6rem;
  height: 6rem;
}
.card-header {
  border-bottom: 1px solid #efeaea !important;
}

.card-body{
  padding-top: 20px !important;
}

.media-body {
  margin-top: 10px;
  .transaction-title {
    color: #606062;
  }
}
</style>
