<template>
  <div>
    <b-overlay
      id="overlay-background"
      :show="lettersloader"
      variant="dark"
      opacity="0.85"
      blur="2px"
      rounded="sm"
      spinner-variant="primary"
      spinner-medium
    >
      <b-card
        class="card-transaction"
        no-body
      >
        <b-card-header>
          <b-card-title>Generate</b-card-title>
          <b-button
            v-if="letters.length > 0"
            id="add-case-btn"
            variant="primary"
            @click="groupletters"
          >
            <span class="text-nowrap">Group Letters</span>
          </b-button>
        </b-card-header>

        <b-card-body>
          <template
            v-if="letters.length > 0"
            class="transaction-item"
          >
            <div
              v-for="letter in letters"
              :key="letter.id"
              class="transaction-item"
            >
              <b-media
                no-body
              >
                <b-media-aside>
                  <b-avatar
                    rounded
                    size="42"
                    variant="light-success"
                  >
                    <feather-icon
                      size="18"
                      icon="FileIcon"
                    />
                  </b-avatar>
                </b-media-aside>
                <b-media-body>
                  <b-link
                    class="transaction-title"
                    :href="letter.path"
                    target="_blank"
                  >
                    {{ letter.letter_field_id }}
                  </b-link>
                </b-media-body>
              </b-media>
            </div>
            <b-row>
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
              >
                <span class="text-muted">{{ 'Showing' }} {{ from }} to {{ to }} of {{ totalPages }} {{ 'Entries' }}</span>
              </b-col>
              <!-- Pagination -->
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
              >

                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalPages"
                  :per-page="perPage"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>

              </b-col>

            </b-row>
          </template>
          <template
            v-else
          >
            <b-alert
              variant="primary"
              show
            >
              <div class="alert-body">
                <span>There are no letters.</span>
              </div>
            </b-alert>
          </template>
        </b-card-body>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import {
  BRow, BCol, BLink, BCard, BCardBody, BMedia, BMediaBody, BCardHeader, BCardTitle, BMediaAside, BAvatar, BButton, BOverlay, BAlert, BPagination,
} from 'bootstrap-vue'
// import store from '@/store'
// import { computed, onUnmounted } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import { showToast } from '@core/mixins/ui/toastnotification'
import { EventBus } from '@/event-bus'
// import { ValidationProvider, ValidationObserver } from 'vee-validate'
// import { required } from '@validations'
import axios from '@axios'
// import lettersStoreModule from './lettersStoreModule'
// import vSelect from 'vue-select'

export default {
  components: {
    BRow, BCol, BCard, BCardBody, BMedia, BMediaBody, BCardHeader, BCardTitle, BMediaAside, BAvatar, BButton, BOverlay, BAlert, BLink, BPagination,
  },
  directives: {
    Ripple,
  },
  mixins: [showToast],
  data() {
    return {
      lettersloader: false,
      letters: [],
      letterTemplates: [],
      totalPages: 0,
      from: 0,
      to: 0,
      currentPage: 1,
      perPage: 10,
    }
  },
  watch: {
    currentPage: {
      handler() {
        this.getLetters()
      },
    },
  },
  created() {
    this.getLetters()
  },
  beforeMount() {
    this.getLetterTemplates()
  },
  methods: {
    getLetters() {
      this.lettersloader = true
      axios.get(`get-letters?page=${this.currentPage}&sortDesc=true`).then(response => {
        if (response.status) {
          this.letters = response.data.data.data
          this.totalPages = response.data.data.total
          this.from = response.data.data.from
          this.to = response.data.data.to
          this.currentPage = response.data.data.current_page
          this.lettersloader = false
        }
      }).catch(() => {
        this.lettersloader = false
        this.failureToast('Letters could not be fetched')
      })
    },
    getLetterTemplates() {
      axios.get('get-letter-templates').then(response => {
        if (response.status) {
          this.letterTemplates = response.data.data.template_ids
        }
      }).catch(() => {
        this.failureToast('Letter Templates could not be fetched')
      })
    },
    async groupletters() {
      this.lettersloader = true
      // console.log(this.letterTemplates)
      for (let i = 0; i < this.letterTemplates.length; i += 1) {
        /* eslint-disable no-await-in-loop */
        await axios.get(`merge-letters?template_id=${this.letterTemplates[i]}`).then(response => {
          console.log(response)
        }).catch(() => {
          this.lettersloader = false
          this.failureToast('Letters could not be merged')
        })
      }
      this.getLetters()
      EventBus.$emit('lettersmerged')
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';
.spinner-border{
  width: 6rem;
  height: 6rem;
}
.card-header {
  border-bottom: 1px solid #efeaea !important;
}

.card-body{
  padding-top: 20px !important;
}

span.spinner-border.text-primary {
    width: 25px;
    height: 25px;
}

.media-body {
  margin-top: 10px;
  a {
    color: #606062;
    font-weight: 450;
  }
}
</style>
