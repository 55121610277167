<template>
  <b-card>
    <div class="navbar-container d-flex content align-items-center">
      <!-- Left Col -->
      <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
        <dark-Toggler class="d-none d-lg-block" />
      </div>
      <div class="nav align-items-center ml-auto">

        <b-link
          class="ml-2"
          @click="logout"
        >
          <feather-icon
            size="16"
            icon="LogOutIcon"
            class="mr-50"
          />
        </b-link>
      </div>

    </div>
  </b-card>
</template>

<script>
import { getUserData } from '@/auth/utils'
import {
  BLink, BCard,
} from 'bootstrap-vue'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import { initialAbility } from '@/libs/acl/config'
import useJwt from '@/auth/jwt/useJwt'
// import locale from '@core/layouts/components/app-navbar/components/Locale.vue'
// import { BCard } from 'bootstrap-vue'

export default {
  components: {
    BLink,
    // BNavbarNav,
    // Navbar Components
    DarkToggler,
    BCard,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      userData: getUserData(),
    }
  },
  methods: {
    logout() {
      // Remove userData from localStorage
      // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

      // Remove userData from localStorage
      localStorage.removeItem('userData')

      // Reset ability
      this.$ability.update(initialAbility)

      // Redirect to login page
      this.$router.push({ name: 'login' })
    },
    getName() {
      return this.userData.data.name
    },
    getRole() {
      const { role } = this.userData
      return role[0]
    },
  },

}
</script>

<style lang="scss">
.content {
  margin-left: 0px !important;
}
</style>
