<template>
  <div>

    <template>
      <Logout />
    </template>

    <b-row
      class="m-10"
    >
      <b-col
        md="6"
        lg="6"
      >
        <letters />
      </b-col>
      <b-col
        md="6"
        lg="6"
      >
        <merged-letters />
      </b-col>
    </b-row>

  </div>
</template>

<script>
import {
  BRow,
  BCol,
} from 'bootstrap-vue'
import Logout from '../layouts/components/Logout.vue'
import Letters from './Letters.vue'
import MergedLetters from './MergedLetters.vue'

export default {
  components: {
    // BLink,
    Logout,
    BRow,
    BCol,
    Letters,
    MergedLetters,
  },
}
</script>

<style>
.m-10 {
  margin: 10px;
}
</style>
